/*-----------------------------------------------------------------------------------

    Template Name: Rasin - Business & Consulting React Template
    Description: Rasin - Business & Consulting React Template is a clean and modern Education Template. It’s suitable for any university, online course, workshop, college, school, kindergarten, course hub or any kind of educational institution. This template includes different homepage demo with 30+ pages carefully designed blocks, which are easy to edit and customize to fit your needs. Template is perfect solution for the create of unique educational websites.
    Author: Thecodude
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

01. Theme default CSS
2. header
3. about
4. slider
5. footer
6. service
7. team
8. cta
9. project
10. testimonial
11. contact
12. carrer
13. blog
14.shop

-----------------------------------------------------------------------------------*/
@import 'animations';
@import 'style';
@import 'responsive';
@import '../fonts/flaticon';
@import '../fonts/icofont'; 


